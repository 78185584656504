<template>
  <vbt-content-box title="Information">
    <order-reference-search-box
      v-model="searchTerm"
      @submit="getReturnedOrder"
    />

    <v-layout class="mt-5">
      <v-flex sm6>
        <v-select
          v-model="printProviderId"
          :disabled="providers.length < 2"
          :items="providers"
          label="Print provider"
        />
      </v-flex>
    </v-layout>

    <order-form
      v-if="showOrderForm"
      :order="order"
      :order-creator="createReturnedOrder"
      @submit="sendReturnedOrder"
      @reset="resetForm"
    />

    <shipping-labels-viewer
      v-if="returnedLabels.shippingLabels.length"
      :labels="returnedLabels.shippingLabels"
    />
  </vbt-content-box>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters,
} from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { ReturnApiService } from '../_services/return.api.service';

import { createReturnedOrder } from '../_meta/returnedOrder';

import OrderForm from '../../_components/OrderForm';
import ShippingLabelsViewer from '../../_components/ShippingLabelsViewer.vue';
import OrderReferenceSearchBox from '../../_components/OrderReferenceSearchBox.vue';

export default {
  name: 'IrOrderReturn',

  components: {
    OrderForm,
    ShippingLabelsViewer,
    OrderReferenceSearchBox,
  },

  data() {
    return {
      searchTerm: '',
      showOrderForm: false,
      printProviderId: null,
      returnedLabels: { shippingLabels: [] },
    };
  },

  computed: {
    ...mapState('return', ['returnedOrder']),

    ...mapGetters('accounts', ['providersOptions']),

    order() {
      return this.printProviderId
        ? this.returnedOrder
          .find(({ printProviderId }) => printProviderId === this.printProviderId)
        : this.returnedOrder[0];
    },

    reshipProviders() {
      return this.returnedOrder.map(({ printProviderId }) => printProviderId);
    },

    providers() {
      const ids = this.reshipProviders.filter(v => v);
      return this.providersOptions.filter(({ value }) => ids.length && ids.includes(value));
    },
  },

  created() {
    this.getAccounts();
  },

  methods: {
    ...mapActions('accounts', ['getAccounts']),

    ...mapMutations('return', ['setReturnedOrder']),

    ...mapActions('return', {
      getReturnedOrder(dispatch) {
        if (this.searchTerm) {
          this.returnedLabels.shippingLabels = [];

          this.wrapToLoadingFn({
            req: dispatch.bind({}, 'getReturnedOrder', { searchTerm: this.searchTerm }),
            onSuccess: () => {
              this.showOrderForm = true;

              if (this.providers.length) {
                this.printProviderId = this.providers[0].value;
              }
            },
          });
        }
      },
    }),

    sendReturnedOrder({ order, selectedItems }) {
      this.$VBlackerTheme.alert.warning({
        text: 'Generate return label?',
      }, () => {
        const form = JSON.parse(JSON.stringify(order));

        form.orderItems = selectedItems.map(item => ({ ...item }));

        form.packages.forEach((pack) => {
          if (pack.description) {
            pack.packageItems = [{ description: pack.description }];
          }

          delete pack.description;
        });

        this.wrapToLoadingFn({
          req: ReturnApiService.sendReturnedOrder.bind({}, {
            orderId: this.returnedOrder.printProviderOrderId,
            form,
          }),
          onSuccess: (response) => {
            this.$VBlackerTheme.notification.success('Order successfully returned');
            this.returnedLabels = response;
            this.showOrderForm = false;
          },
        });
      });
    },

    resetForm() {
      this.searchTerm = '';
      this.showOrderForm = false;
      this.setReturnedOrder(createReturnedOrder());
    },

    createReturnedOrder,
    wrapToLoadingFn,
  },
};
</script>
